import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { TimedModal } from "@commonwealth-savings-wizard/theme";
import confirmation from "../assets/images/confirmation.svg";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Grid = makeShortcode("Grid");
const Box = makeShortcode("Box");
const Text = makeShortcode("Text");
const Image = makeShortcode("Image");
const Partial = makeShortcode("Partial");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <Grid sx={{
      minHeight: 400,
      mx: [0, null, -6],
      px: [0, null, 6],
      gridTemplateColumns: ["1fr", null, "1fr 1fr"],
      gridColumnGap: 5,
      gridRowGap: 4,
      borderRadius: ["none", null, "lg"],
      boxShadow: ["none", null, "raised"]
    }} mdxType="Grid">
      <Box sx={{
        py: [0, null, 5],
        gridRow: [2, null, 1]
      }} mdxType="Box">
        <Text variant="eyebrow" mdxType="Text">{"Successfully submitted!"}</Text>
        <h1>{`Congratulations!`}</h1>
        <p>{`You’re on your way to growing your emergency fund.`}</p>
        <p>{`Feel reassured knowing you’ve taken the first step on your path to financial security.`}</p>
        <p>{`You’ll receive an email confirmation and an Emergency Fund Card in the mail.`}</p>
      </Box>
      <Image src={confirmation} sx={{
        height: ["calc(150px + 35vw)", null, "auto"],
        mr: [-3, null, -6],
        ml: [-3, null, 0],
        borderTopRightRadius: ["none", null, "lg"],
        borderBottomRightRadius: ["none", null, "lg"],
        bg: "green"
      }} mdxType="Image" />
    </Grid>
    <TimedModal delay={12000} size="full" mdxType="TimedModal">
  <Partial slug="modal-finish-final" mdxType="Partial" />
    </TimedModal>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      